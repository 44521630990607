var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "propertyView commonF",
      staticStyle: { padding: "16px 0", "background-color": "#fff" },
    },
    [
      _c("infoTable", { attrs: { tableData: _vm.tableData, num: 1 } }),
      _c(
        "el-button",
        {
          staticStyle: { display: "block", margin: "20px auto" },
          attrs: { type: "primary" },
          on: {
            click: function ($event) {
              return _vm.$router.go("-1")
            },
          },
        },
        [_vm._v("返回")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }